import React from "react"
import { Helmet } from "react-helmet"
import PublicLayout from "../components/layouts/PublicLayout"
import ContactUsForm from "../components/forms/ContactUsForm"

const ContactUsPage = props => {
  return (
    <PublicLayout
      headline={"How can we help you?"}
      subheading={"Let us know if you have a question or if we can help in anyway."}
      canonicalUrl="https://launchplan.com/contact-us"
      showFreeTrial={false}
    >
      <Helmet>
        <title>Contact Us about our Business Plan Software | LaunchPlan</title>
        <meta
          name="description"
          content="LaunchPlan business plan software offers smart and simple solutions for entrepreneurs, colleges, and veterans looking to build a better business plan."
        />
        <meta
          property="og:title"
          content="Contact Us about our Business Plan Software | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/contact-us" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <ContactUsForm />
    </PublicLayout>
  )
}

export default ContactUsPage
